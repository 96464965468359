<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>Мы начинаем шить. Вот он и наступил этот немного волнительный момент! Скоро и он будет пройден, и ваши усилия обязательно воплотятся в маленький чудесный наряд ))) А начнём мы с самого простого с панталон и подъюбника.</p>
            <p class='nomargin'>В уроке есть 2 вида обработки пояса панталон:</p>
            <ol>
                <li>Вариант на резинке. Отлично подойдёт для начинающих.</li>
                <li>Вариант с поясом. Посложнее.</li>
            </ol>
            <p>Выбирайте по своим силам и задачам.</p>
            <p class="nomargin">Также вас ждут небольшие уроки по швам и утюжке для начинающих: </p>
            <p class="nomargin"><router-link to="/mycourses/costume/10">ТОП 5 ручных строчек</router-link></p>
            <p class="nomargin"><router-link to="/mycourses/costume/11">ТОП 5 машинных строчек</router-link></p>
            <p><router-link to="/mycourses/costume/12">Утюжка</router-link></p>
            <h3>5.1 Панталоны. Делаем основу</h3>
        </div>

        <VideoView video-id="77307857da1243c3bb61a8d475561580"/>

        <div class="MBtextcont">
            <h3>5.2 Панталоны на поясе</h3>
        </div>

        <VideoView video-id="9562ae677974426f977a4f67ecc34e73"/>

        <div class="MBtextcont">
            <h3>5.3 Панталоны на резинке</h3>
        </div>

        <VideoView video-id="5c5335ca778e49058e5826a365984e1d"/>

        <div class="MBtextcont">
            <h3>5.4 Нижняя юбка</h3>
        </div>

        <VideoView video-id="5d4526d46c99485b934e1b19461979ce"/>

        <div class="MBtextcont">
            <h3>5.5 Бонусное занятие по пошиву чулок</h3>
        </div>

        <VideoView video-id="6f85a00652ed4b499bdb43d4108fda36"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>